import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 25 26">
    <path
      fill={color}
      d="M13,25.5v-0.8l0.3,0c2.8-0.3,5.2-2.3,6-5l0.2-0.7l0.4,0.6c1,1.4,2,2.1,3,1.8c1.3-0.4,2.3-2.5,2.2-4.8
	c-0.2-3.7-2.2-5.6-4.6-7.8c-0.3-0.3-0.6-0.5-0.9-0.8l-0.1-0.1l0-0.1c-0.4-1.8-1.6-3.4-3.2-4.4L16,3.2l0.7-2.6c0-0.1,0-0.3-0.1-0.4
	C16.6,0.1,16.5,0,16.3,0S16.1,0,16,0.1c-0.1,0.1-0.2,0.2-0.2,0.3l-0.6,2.4l-0.3-0.1c-1.5-0.5-3.1-0.5-4.5,0L9.9,2.7L9.3,0.4
	c0-0.2-0.1-0.3-0.2-0.3C8.9,0,8.8,0,8.7,0c-0.1,0-0.3,0.1-0.3,0.2c-0.1,0.1-0.1,0.2,0,0.4L9,3.2L8.9,3.3c-1.6,1-2.8,2.6-3.2,4.5
	l0,0.1L5.5,7.9C5.3,8.2,5,8.5,4.7,8.7c-2.5,2.2-4.5,4.1-4.7,7.8c-0.1,2.2,0.9,4.4,2.2,4.8c1,0.3,1.9-0.3,2.9-1.8L5.5,19l0.2,0.7
	c0.8,2.7,3.2,4.7,6,5l0.3,0v0.8c0,0.4,0.3,0.5,0.5,0.5C12.7,26,13,25.9,13,25.5z M19.6,9.3l0.5,0.5c2,1.8,3.8,3.7,3.9,6.7
	c0.1,1.3-0.4,3.4-1.4,3.7c-0.1,0-0.2,0-0.3,0c-0.5,0-1.1-0.4-1.6-1.3l-1.1-1.7L19.6,9.3L19.6,9.3z M5.4,17.3L4.3,19
	c-0.6,0.9-1.1,1.3-1.6,1.3c-0.1,0-0.2,0-0.3,0C1.2,19.9,1,17.6,1,16.5c0.1-3.1,1.9-4.9,3.9-6.7l0.5-0.5L5.4,17.3L5.4,17.3z M6.6,8
	c0.6-2.8,3.1-4.8,6-4.8c2.8,0,5.3,2,6,4.8l0.1,0.4H6.5L6.6,8z M6.4,9.4h12.2v3.5H6.4V9.4z M6.4,13.9h12.2v3.5H6.4V13.9z M6.5,18.8
	l-0.1-0.4h12.1l-0.1,0.4c-0.5,2.9-3.1,4.9-6,4.9C9.6,23.7,7.1,21.6,6.5,18.8z"
    />
  </svg>
);
